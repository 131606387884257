<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <vx-card
          v-if="!isLoading"
          title="Transfer Fund (ใช้ได้เฉพาะช่วงเวลา 02:30 - 22:00)"
        >
          <div class="mb-3">
            <vs-input label="Available Transfer Balance (ยอดเงินที่โยกได้)" readonly v-model="deposit" type ="number"  class="w-full my-2" />
            <vs-input label="Transfer Amount (ขั้นต่ำ 1,000)" v-model="transferAmount" type ="number" class="w-full my-2" />
          </div>
          <div class="my-3">
            <p>* ระบบอาจจะใช้เวลา 3-5 นาทีในการโยกเงินฝาก</p>
            <p>ใช้ได้เฉพาะช่วงเวลา 02:30 - 22:00</p>
          </div>
          <vs-button
            class="mr-2"
            color="primary"
            @click="setToMax"
            >Max</vs-button
          >
          <vs-button
            class="mr-2"
            color="success"
            @click="submit"
            :disabled="isAmountInvalid"
            >Submit</vs-button
          >
        </vx-card>
        <vx-card v-else> Loading... </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    StatisticsCardLine,
    vSelect,
    flatPickr
  },
  data () {
    return {
      isReadyToTransfer: false,
      cookie: null,
      isLoading: true,
      bankPlaceHolder: 'ระบบกำลังดึงข้อมูล กรุณารอสักครู่...',
      deposit: 0.0,
      withdraw: 0.0,
      startDate: this.moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      config: {
        enableTime: true
      },
      paymentInfo: {},
      transferPaymentResult: {},
      transferAmount: 0
    }
  },
  async mounted () {
    await this.getBalance()
    this.isLoading = false
  },
  methods: {
    async getBalance () {
      try {
        const getBalance = await axios
          .post('/payment/api/balance')

        if (getBalance.data.status.success) {
          const { balance, balance_withdraw } = getBalance.data.data
          this.deposit = balance
          this.withdraw = balance_withdraw
        }
      } catch (e) {
        console.error(e)
      }
    },
    setToMax () {
      this.transferAmount = this.deposit
    },
    async clearData () {
      this.deposit = 0
      this.transferAmount = 0
      await this.getPaymentInfo()
    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    },
    async submit () {
      const {
        transferAmount
      } = this

      const payload = {
        amount: transferAmount,
        cookie: this.cookie
      }

      try {
        const response = await axios.post('/payment/transfer/submit', payload)

        if (response && response.data && response.data.success) {
          this.$vs.notify({
            time: 10000,
            color: 'success',
            position: 'top-right',
            icon: 'success',
            title: `โยกเงินฝาก ${response.data.amount} บาทสำเร็จ`,
            text: 'ระบบอาจจะใช้เวลา 3-5 นาทีในการโยกเงินฝาก'
          })
        }

        this.isReadyToTransfer = true
        this.clearData()
        this.getBalance()
      } catch (error) {
        // Handle the error
        console.error(error)
        this.$vs.notify({
          time: 10000,
          color: 'error',
          position: 'top-right',
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'โปรดลองทำรายการใหม่อีกครั้งในภายหลัง (ใช้ได้เฉพาะช่วงเวลา 02:30 - 22:00)'
        })
      }
    }
  },
  computed: {
    isAmountInvalid () {
      const amount = parseFloat(this.transferAmount)
      const minAmount = 1000
      const maxAmount = this.deposit
      return isNaN(amount) || amount < minAmount || amount > maxAmount
    }
  }
}
</script>

<style scoped>
.b {
  border-radius: 5px;
  padding: 5px;
  /* Add padding here */
  color: white;
  background-color: #792cffde;
}

.b-success {
  background-color: #03c837de;
}

.b-failed {
  background-color: #ff0000de;
}

.b-create {
  background-color: #ffb300de;
}

.text {
  color: red;
  font-size: 15px;
}

.topup-detail-table {
  border-collapse: collapse;
  border: 1px solid #e5e5e5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.topup-detail-table th,
.topup-detail-table td {
  border: 1px solid #e5e5e5;
  padding: 8px;
}

.topup-detail-table th {
  background-color: #f5f5f5;
}
</style>
